<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->

      <div class="brand-logo mt-0 pt-0">
        <img style="height: 150px" src="@/assets/images/logo.png" alt="logo" class="th-logo"/>

      </div>

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-0">
        <div class="w-100 d-lg-flex align-items-center justify-content-center "
             v-bind:style="{'background-image':`url(${imgUrl})`} "
             style="height: 100%; background-repeat: no-repeat;background-size: cover;">
          <!--          <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />-->
        </div>
      </b-col>

      <!-- /Left Text-->

      <!-- Login-->
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
          >
            Willkommen bei Treasure Contracts! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Wenn du bereits Zugangsdaten hast bitte hier einloggen. Bist du ein neuer Makler oder Eigentümer? Erhalte
            exklusiven Zugang zu unserem Ecosystem unter der Anfragemail : <a href="mailto:office@treasurehome.at">office@treasurehome.at</a>
          </b-card-text>

          <b-alert v-bind:show="loginError != null" class="p-1" variant="danger"><i
              class="fas fa-exclamation-triangle"></i> {{ loginError }}
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent
            >
              <!-- email -->
              <b-form-group
                  label="Email"
                  label-for="login-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                >
                  <b-form-input
                      id="login-email"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>

                <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                >
                  <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        type="password"
                        name="login-password"
                        placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <!--                      <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />-->
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>


        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {mapActions} from "vuex";
// import { required, email } from '@validations'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      loginError: null,
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/contractLogo.jpg'),
      passwordFieldType: 'password'
      // validation rulesimport store from '@/store/index'
      // required,
      // email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      return this.sideImg
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions('client', ['login']),
    validationForm() {
      let $this = this;
      $this.loginError = null;
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          $this.login({
            username: $this.username,
            password: $this.password
          }).then($this.onLoginSuccess, $this.onLoginFailed)
        }
      })
    },
    onLoginSuccess() {
      this.redirect("/");
    },
    onLoginFailed(e) {
      console.log("onLoginFailed()", e)
      this.loginError = e;
      this.$forceUpdate();
    },
    redirect(defaultRedirect) {
      console.log("redirect()")
      defaultRedirect = defaultRedirect || "/"
      let url = new URL(window.location.href);
      let returnUrl = url.searchParams.get("returnUrl");
      if (returnUrl != null && returnUrl !== "") {
        returnUrl = returnUrl.trim();
        //even that vue check for xss attack we made a double check
        //remove all http or other protocols
        returnUrl = returnUrl.replace("http://", "").replace("https://", "").replace("://", "");
        //check for points
        returnUrl = returnUrl.replace(".", "");
        this.$router.push(returnUrl)
      } else
        this.$router.push({path: defaultRedirect})
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/page-auth.scss';
</style>
